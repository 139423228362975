import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { matchValidator } from '@shared/validators/match.validator';
import { TranslateService } from '@ngx-translate/core';
import { AccountSettingsService } from 'src/app/core/services/account-settings.service';

@Component({
  selector: 'app-set-pass-code',
  templateUrl: './set-pass-code.component.html',
  styleUrls: ['./set-pass-code.component.scss']
})
export class SetPassCodeComponent implements AfterViewInit {

  passCodeForm: FormGroup = this.formBuilder.group({
    passCode: ['', [Validators.required, Validators.pattern('^[0-9]{4}$')]],
    confirmPassCode: ['', [Validators.required, matchValidator('passCode')]]
  });

  submitted: boolean = false;
  loading: boolean = false;

  @ViewChild('passCodeRef') passCodeRef!: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private accountSettingsService: AccountSettingsService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar) { }

  ngAfterViewInit() {
    // set focus to pass code
    setTimeout(() => {
      this.passCodeRef.nativeElement.focus();
    });
  }

  async onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.passCodeForm.invalid) {
      return;
    }

    this.loading = true;

    const settings = {
      passCode: this.passCodeForm.controls['passCode'].value
    };
    await this.accountSettingsService.saveAccountSettings(settings);
    this.loading = false;
    this.snackBar.open(this.translateService.instant('alerts.updatePassCodeSuccess'), '✖', { direction: this.translateService.instant('uiDirection') });
  }

}
