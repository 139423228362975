import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha-2';

import { MaterialModule } from 'src/material-module';
import { AuthRoutingModule } from './auth-routing.module';
import { CallbackComponent } from './views/callback/callback.component';
import { SetPassCodeComponent } from './views/set-pass-code/set-pass-code.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { LoginComponent } from './views/login/login.component';
import { SharedModule } from '@shared';
import { AuthHeaderComponent } from './components/auth-header/auth-header.component';
import { AuthFooterComponent } from './components/auth-footer/auth-footer.component';

@NgModule({
  declarations: [
    AuthHeaderComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CallbackComponent,
    UnauthorizedComponent,
    SetPassCodeComponent,
    AuthFooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule,
    MaterialModule,
    SharedModule,
    AuthRoutingModule
  ]
})
export class AuthModule { }
