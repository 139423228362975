import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth';
import { matchValidator } from '@shared/validators/match.validator';
import { patternValidator } from '@shared/validators/pattern.validator';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(8), patternValidator(/\d/, { hasNumber: true }),
    patternValidator(/[A-Z]/, { hasCapitalCase: true }), patternValidator(/[a-z]/, { hasSmallCase: true })]],
    confirmPassword: ['', [Validators.required, matchValidator('password')]]
  });

  isCodeValid: boolean = false;
  passwordField: boolean = true;
  submitted: boolean = false;
  loading: boolean = false;
  passwordCofirmed: boolean = false;

  @ViewChild('passwordRef') passwordRef?: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (this.route.snapshot.queryParams['code']) {
      this.validateResetPasswordCode(this.route.snapshot.queryParams['code']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.resetPassword(this.route.snapshot.queryParams['code'], this.resetPasswordForm.controls['password'].value)
      .subscribe({
        next: (res: boolean) => {
          this.passwordCofirmed = true;
          this.loading = false;
          this.snackBar.open(this.translateService.instant('alerts.resetPassSuccess'), '✖', { direction: this.translateService.instant('uiDirection') });
        },
        error: (err: any) => {
          this.loading = false;
          this.snackBar.open(err.error[0], '✖', { direction: this.translateService.instant('uiDirection') });
        }
      });
  }

  private validateResetPasswordCode(code: string) {
    this.authService.validateResetPasswordCode(code)
      .subscribe({
        next: (res: boolean) => {
          this.isCodeValid = true;
          // Set focus to password
          setTimeout(() => this.passwordRef?.nativeElement.focus());
        },
        error: (err: any) => {
          this.router.navigate(['/login']);
          this.snackBar.open(this.translateService.instant('resetPassword.linkNotValid'), '✖', { direction: this.translateService.instant('uiDirection') });
        }
      });
  }

}
