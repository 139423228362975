<div class="auth">
  <app-auth-header></app-auth-header>
  <div class="row justify-content-center">
    <div class="col-md-4 col-xl-4">
      <mat-card class="auth__card" *ngIf="!emailSent">
        <mat-card-header>
          <mat-card-title class="margin-bottom-m">
            <h2>{{ "auth.forgotPassword" | translate }}</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="forgotPassForm" (ngSubmit)="onSubmit()" id="forgotPasswordForm" class="login__form">
            <mat-form-field class="login__text-field">
              <mat-label>{{ "forgotPassword.email" | translate }}</mat-label>
              <input id="textbox_forgetPasswordEmail" matInput [placeholder]="('forgotPassword.email' | translate)"
                formControlName="email" #emailRef autocomplete="Off" />
              <mat-error *ngIf="submitted && forgotPassForm.controls['email'].errors?.['required']">
                {{ "forgotPassword.validation.emailRequired" | translate }}
              </mat-error>
              <mat-error *ngIf="submitted && forgotPassForm.controls['email'].errors?.['email']">
                {{ "forgotPassword.validation.emailValid" | translate }}
              </mat-error>
            </mat-form-field>
            <div class="g-recaptcha">
              <re-captcha class="d-flex justify-content-center" formControlName="captcha"
                siteKey="6Le7RpIaAAAAAB-KFUibaTPCEPU3p4fgyZXxN9LO">
              </re-captcha>
              <mat-error *ngIf="submitted && forgotPassForm.controls['captcha'].errors?.['required']">
                {{ "forgotPassword.validation.captchaRequired" | translate }}
              </mat-error>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions class="flex-column">
          <button id="btn_continue" mat-flat-button color="primary" type="submit" form="forgotPasswordForm"
            class="button--block d-flex margin-bottom-l" [class.spinner]="loading" [disabled]="loading">
            {{ "buttons.continue" | translate }}
          </button>
          <a [routerLink]="['/login']" id="btn_back" class="text-primary auth__link" *ngIf="!loading">
            {{ 'forgotPassword.backToLogin' | translate }}
          </a>
        </mat-card-actions>
      </mat-card>
      <mat-card class="auth__card" *ngIf="emailSent">
        <div class="d-flex flex-column align-items-center">
          <svg width="177" height="160" viewBox="0 0 177 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_54186_45511)">
              <path
                d="M175.447 61.1872C175.384 61.1873 175.322 61.1677 175.27 61.1312L89.3911 0.904351C89.1298 0.721922 88.8192 0.624429 88.5012 0.624975C88.1832 0.625522 87.873 0.724079 87.6123 0.907404L2.35209 61.1306C2.28466 61.1782 2.20119 61.1969 2.12006 61.1826C2.03893 61.1682 1.96677 61.122 1.91947 61.0541C1.87217 60.9862 1.8536 60.9022 1.86784 60.8205C1.88207 60.7388 1.92796 60.6661 1.9954 60.6185L87.2557 0.395299C87.6207 0.138683 88.055 0.000739726 88.5002 2.96675e-06C88.9454 -0.000733793 89.3802 0.13577 89.7461 0.391177L175.625 60.6179C175.679 60.6559 175.72 60.7104 175.741 60.7735C175.763 60.8365 175.763 60.9047 175.744 60.9682C175.724 61.0318 175.684 61.0873 175.631 61.1266C175.578 61.166 175.513 61.1872 175.447 61.1872L175.447 61.1872Z"
                fill="#72777F" />
              <path
                d="M7.22412 63.3221L88.5857 2.60132L170.565 67.6426L92.7778 114.078L50.5463 104.385L7.22412 63.3221Z"
                fill="#DEE3EB" />
              <path
                d="M54.1109 142.76H13.9163C13.6695 142.76 13.425 142.711 13.1969 142.616C12.9688 142.522 12.7615 142.382 12.5869 142.207C12.4122 142.031 12.2737 141.823 12.1792 141.593C12.0847 141.363 12.036 141.117 12.036 140.869C12.036 140.62 12.0847 140.374 12.1792 140.145C12.2737 139.915 12.4122 139.706 12.5869 139.531C12.7615 139.355 12.9688 139.216 13.1969 139.121C13.425 139.026 13.6695 138.977 13.9163 138.978H54.1109C54.3577 138.977 54.6022 139.026 54.8303 139.121C55.0584 139.216 55.2657 139.355 55.4403 139.531C55.615 139.706 55.7535 139.915 55.848 140.145C55.9425 140.374 55.9912 140.62 55.9912 140.869C55.9912 141.117 55.9425 141.363 55.848 141.593C55.7535 141.823 55.615 142.031 55.4403 142.207C55.2657 142.382 55.0584 142.522 54.8303 142.616C54.6022 142.711 54.3577 142.76 54.1109 142.76Z"
                fill="#CFE5FF" />
              <path
                d="M28.3372 134.63H13.9163C13.6695 134.63 13.425 134.581 13.1969 134.486C12.9688 134.391 12.7615 134.252 12.5869 134.077C12.4122 133.901 12.2737 133.692 12.1792 133.463C12.0847 133.233 12.036 132.987 12.036 132.739C12.036 132.49 12.0847 132.244 12.1792 132.014C12.2737 131.785 12.4122 131.576 12.5869 131.401C12.7615 131.225 12.9688 131.086 13.1969 130.991C13.425 130.896 13.6695 130.847 13.9163 130.848H28.3372C28.584 130.847 28.8285 130.896 29.0566 130.991C29.2847 131.086 29.492 131.225 29.6666 131.401C29.8413 131.576 29.9798 131.785 30.0743 132.014C30.1689 132.244 30.2175 132.49 30.2175 132.739C30.2175 132.987 30.1689 133.233 30.0743 133.463C29.9798 133.692 29.8413 133.901 29.6666 134.077C29.492 134.252 29.2847 134.391 29.0566 134.486C28.8285 134.581 28.584 134.63 28.3372 134.63Z"
                fill="#CFE5FF" />
              <path
                d="M89.7298 97.1428C89.4243 97.1431 89.1216 97.0834 88.8388 96.967L38.6606 76.001V10.3736C38.6613 9.79332 38.8905 9.23696 39.298 8.82661C39.7055 8.41626 40.258 8.18543 40.8343 8.18475H137.097C137.674 8.18543 138.226 8.41626 138.634 8.82661C139.041 9.23696 139.27 9.79332 139.271 10.3736V76.0468L139.177 76.0874L90.6477 96.9568C90.3571 97.0798 90.045 97.143 89.7298 97.1428Z"
                fill="white" />
              <path
                d="M89.7298 97.299C89.404 97.2994 89.0813 97.2358 88.7797 97.1118L38.5054 76.1054V10.3737C38.5061 9.7519 38.7517 9.15579 39.1883 8.71613C39.6249 8.27646 40.2169 8.02915 40.8343 8.02844H137.097C137.715 8.02915 138.307 8.27646 138.744 8.71613C139.18 9.15579 139.426 9.7519 139.426 10.3737V76.15L90.7087 97.1005C90.3988 97.2318 90.066 97.2993 89.7298 97.299ZM39.1264 75.6877L89.0165 96.5336C89.4819 96.7237 90.003 96.7206 90.4661 96.5249L138.805 75.7372V10.3737C138.805 9.91772 138.625 9.48058 138.305 9.15817C137.984 8.83575 137.55 8.65438 137.097 8.65384H40.8343C40.3815 8.65438 39.9474 8.83575 39.6273 9.15817C39.3071 9.48058 39.127 9.91772 39.1264 10.3737L39.1264 75.6877Z"
                fill="#72777F" />
              <path
                d="M174.826 60.5618H174.764L139.116 75.8903L90.3415 96.863C90.1509 96.9437 89.9464 96.9858 89.7396 96.9869C89.5329 96.988 89.3279 96.948 89.1365 96.8693L38.8158 75.8465L2.29165 60.5868L2.23585 60.5618H2.17368C1.59738 60.5625 1.04487 60.7933 0.637362 61.2036C0.229855 61.614 0.000639451 62.1704 0 62.7507V157.811C0.000640272 158.391 0.229856 158.948 0.637362 159.358C1.04487 159.769 1.59738 159.999 2.17368 160H174.826C175.403 159.999 175.955 159.769 176.363 159.358C176.77 158.948 176.999 158.391 177 157.811V62.7507C176.999 62.1704 176.77 61.614 176.363 61.2036C175.955 60.7933 175.403 60.5625 174.826 60.5618ZM176.379 157.811C176.379 158.226 176.215 158.623 175.924 158.916C175.633 159.21 175.238 159.374 174.826 159.375H2.17368C1.76195 159.374 1.36713 159.21 1.07599 158.916C0.784852 158.623 0.621217 158.226 0.621053 157.811V62.7507C0.621616 62.3464 0.777359 61.9579 1.05572 61.6666C1.33408 61.3752 1.71351 61.2034 2.1147 61.1872L38.8158 76.522L88.8976 97.4478C89.4397 97.6699 90.0471 97.6665 90.5868 97.4384L139.116 76.5689L174.888 61.1872C175.289 61.2046 175.667 61.3769 175.945 61.6681C176.223 61.9593 176.378 62.3471 176.379 62.7507V157.811Z"
                fill="#72777F" />
              <path
                d="M84.4869 79C82.9301 79.0029 81.4146 78.4959 80.1689 77.5554L80.0917 77.4971L63.8305 64.9582C63.0775 64.377 62.4454 63.6522 61.9704 62.8251C61.4955 61.9979 61.187 61.0847 61.0625 60.1374C60.938 59.1902 61 58.2276 61.2449 57.3045C61.4898 56.3813 61.9128 55.5158 62.4898 54.7574C63.0668 53.9989 63.7865 53.3624 64.6078 52.884C65.4291 52.4057 66.3359 52.095 67.2764 51.9696C68.2169 51.8442 69.1728 51.9066 70.0894 52.1533C71.0059 52.3999 71.8653 52.826 72.6184 53.4071L83.1512 61.541L108.042 28.8487C108.619 28.0904 109.339 27.4541 110.16 26.9761C110.982 26.498 111.889 26.1876 112.829 26.0626C113.77 25.9375 114.726 26.0003 115.642 26.2473C116.559 26.4943 117.418 26.9207 118.171 27.5021L118.016 27.7137L118.175 27.5052C119.693 28.6807 120.687 30.4149 120.938 32.3274C121.188 34.2398 120.676 36.1743 119.512 37.7064L90.2345 76.1605C89.5574 77.0466 88.6867 77.7637 87.6899 78.2561C86.6931 78.7485 85.5971 79.0031 84.4869 79Z"
                fill="#CFE5FF" />
            </g>
            <defs>
              <clipPath id="clip0_54186_45511">
                <rect width="177" height="160" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <mat-card-header class="text-center margin-top-l margin-bottom-base">
          <mat-card-title>
            {{'forgotPassword.emailHasSent' | translate}}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center margin-bottom-base">
          <p>{{'forgotPassword.linkHasSent' | translate}}</p>
        </mat-card-content>
        <mat-card-actions>
          <a [routerLink]="['/login']" id="btn_backToLogin" class="button--block text-primary auth__link">
            {{'forgotPassword.backToLogin' | translate}}
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="auth__footer">
    <app-auth-footer></app-auth-footer>
  </div>
</div>