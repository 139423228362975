import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard } from '../core/guards/auth.guard';
import { CallbackComponent } from './views/callback/callback.component';
import { SetPassCodeComponent } from './views/set-pass-code/set-pass-code.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { UnauthorizedComponent } from './views/unauthorized/unauthorized.component';
import { LoginComponent } from './views/login/login.component';

const authRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'callback', component: CallbackComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'set-pass-code', component: SetPassCodeComponent, canActivate: [authGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
