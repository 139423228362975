<div class="auth__header">
  <app-logo></app-logo>
  <button id="btn_switchLanguage" mat-button [matMenuTriggerFor]="menu">
    <icon name="public" class="margin-end-s"></icon>
    <span>
      {{ 'tool-bar.menu.switch-language' | translate }}
    </span>
    <icon name="arrow_drop_down" class="margin-start-s"></icon>
  </button>
  <mat-menu #menu="matMenu">
    <button id="btn_english" mat-menu-item (click)="switchLanguageService.useLanguage('en')">
      English
    </button>
    <button id="btn_arabic" mat-menu-item (click)="switchLanguageService.useLanguage('ar')">
      العربية
    </button>
  </mat-menu>
</div>