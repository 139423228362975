<div class="footer">
    <div class="row d-flex alig align-items-center">
        <div class="col-md-4 footer__start">
            <span class="footer__text">
                © {{copyrightYear}} Copyright:
                <a id="btn_edaraLink" href="https://getedara.com" class="footer__link">getedara.com</a>
            </span>
        </div>
        <div class="col-md-4 footer__center">
            <div class="">
                <a id="btn_termsOfService" href="https://getedara.com/terms.html" class="footer__link">
                    {{"footer.termsOfService" | translate}}
                </a>
                <div class="seperator">
                    <mat-divider vertical></mat-divider>
                </div>
                <div class="d-flex">
                    <a id="btn_facebook" target="_blank" href="https://www.facebook.com/getedara" mat-icon-button>
                        <svg class="social-icon" width="16" height="16" viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.08 3.54636H11.3333V1.42636C10.7265 1.36326 10.1168 1.3321 9.50668 1.33303C7.69334 1.33303 6.45334 2.43969 6.45334 4.46636V6.21303H4.40668V8.58636H6.45334V14.6664H8.90668V8.58636H10.9467L11.2533 6.21303H8.90668V4.6997C8.90668 3.9997 9.09334 3.54636 10.08 3.54636Z" />
                        </svg>
                    </a>
                    <a id="btn_twitter" target="_blank" href="https://twitter.com/getedara" mat-icon-button>
                        <svg class="social-icon" width="16" height="16" viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.9733 4.00033C14.46 4.23366 13.9067 4.38699 13.3333 4.46033C13.92 4.10699 14.3733 3.54699 14.5867 2.87366C14.0333 3.20699 13.42 3.44033 12.7733 3.57366C12.2467 3.00033 11.5067 2.66699 10.6667 2.66699C9.10001 2.66699 7.82001 3.94699 7.82001 5.52699C7.82001 5.75366 7.84667 5.97366 7.89334 6.18033C5.52001 6.06033 3.40667 4.92033 2.00001 3.19366C1.75334 3.61366 1.61334 4.10699 1.61334 4.62699C1.61334 5.62033 2.11334 6.50033 2.88667 7.00033C2.41334 7.00033 1.97334 6.86699 1.58667 6.66699V6.68699C1.58667 8.07366 2.57334 9.23366 3.88001 9.49366C3.46049 9.60847 3.02007 9.62444 2.59334 9.54033C2.77441 10.1086 3.12903 10.6059 3.60735 10.9623C4.08567 11.3186 4.66364 11.5161 5.26001 11.527C4.24909 12.3273 2.996 12.7599 1.70667 12.7537C1.48001 12.7537 1.25334 12.7403 1.02667 12.7137C2.29334 13.527 3.80001 14.0003 5.41334 14.0003C10.6667 14.0003 13.5533 9.64033 13.5533 5.86033C13.5533 5.73366 13.5533 5.61366 13.5467 5.48699C14.1067 5.08699 14.5867 4.58033 14.9733 4.00033Z" />
                        </svg>
                    </a>
                    <a id="btn_linkedin" target="_blank" href="https://www.linkedin.com/company/edrak-software/about/"
                        mat-icon-button>
                        <svg class="social-icon" width="16" height="16" viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M4.62666 3.33302C4.62648 3.68665 4.48584 4.02571 4.23566 4.27564C3.98549 4.52556 3.64628 4.66587 3.29266 4.66569C2.93904 4.66551 2.59997 4.52487 2.35004 4.27469C2.10012 4.02452 1.95981 3.68531 1.95999 3.33169C1.96017 2.97807 2.10081 2.639 2.35099 2.38908C2.60116 2.13915 2.94037 1.99885 3.29399 1.99902C3.64761 1.9992 3.98668 2.13985 4.23661 2.39002C4.48653 2.64019 4.62684 2.9794 4.62666 3.33302ZM4.66666 5.65302H1.99999V13.9997H4.66666V5.65302ZM8.87999 5.65302H6.22666V13.9997H8.85332V9.61969C8.85332 7.17969 12.0333 6.95302 12.0333 9.61969V13.9997H14.6667V8.71302C14.6667 4.59969 9.95999 4.75302 8.85332 6.77302L8.87999 5.65302Z" />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-md-4 footer__end ">
            <div class="margin-end-m version-info">
                Current build: {{version}}
            </div>
        </div>
    </div>
</div>