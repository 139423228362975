import { Component } from '@angular/core';
import { SwitchLanguageService } from '@core';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.scss']
})
export class AuthHeaderComponent {

  constructor(public switchLanguageService: SwitchLanguageService) { }

}
