import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from 'ngx-cookie-service';
import { SecurityService } from 'src/app/core/services/security.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@core/services/app.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
    rememberMe: [false]
  });

  returnUrl?: string;
  passwordField: boolean = true;
  submitted: boolean = false;
  loading: boolean = false;

  @ViewChild('emailRef') emailRef?: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private cookieService: CookieService,
    private securityService: SecurityService,
    private appService: AppService
  ) {
    // Redirect to home if already logged in
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }

    this.appService.handleNavState("closed");
    this.appService.handleBodyControls(false);
  }

  ngOnInit() {
    // Get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    // Rememebr me
    if (this.cookieService.check('rememberMe')) {
      this.loginForm.controls['email'].setValue(this.securityService.decrypt(this.cookieService.get('rememberMe')));
      this.loginForm.controls['rememberMe'].setValue(true);
    }

    // Set focus to email
    this.emailRef?.nativeElement.focus();
  }

  ngOnDestroy() {
    this.appService.handleNavState("expanded");
    this.appService.handleBodyControls(true);
  }

  onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    // Remember me
    if (this.loginForm.controls['rememberMe'].value === true) {
      this.cookieService.set('rememberMe', this.securityService.encrypt(this.loginForm.controls['email'].value), 30);
    } else {
      this.cookieService.delete('rememberMe');
    }

    this.authService.login(this.loginForm.controls['email'].value, this.loginForm.controls['password'].value)
      .subscribe({
        next: (res: string) => {
          this.loading = false;
          this.snackBar.open(this.translateService.instant('alerts.loginError'), '✖', { direction: this.translateService.instant('uiDirection') });
        }
      });
  }

}
