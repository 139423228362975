<header class="page-header">
  <h4 id="txt_setPassCode" class="typo-display-small">
    {{ 'setPassCode.title' | translate }}
  </h4>
</header>

<p *ngIf="loading" class="mat-spinner">
  <mat-spinner diameter="40"></mat-spinner>
</p>

<form [formGroup]="passCodeForm" (ngSubmit)="onSubmit()">
  <div class="col-md-4 mb-3">
    <mat-form-field>
      <mat-label>
        {{ 'setPassCode.passCode' | translate }}
      </mat-label>
      <input id="textbox_setPassCodePassCode" matInput type="password" maxlength="4" autocomplete="off" #passCodeRef
        formControlName="passCode" [placeholder]="('setPassCode.passCode' | translate)" />
      <mat-error *ngIf="submitted && passCodeForm.controls['passCode'].errors?.['required']">
        {{ 'setPassCode.validation.passCodeRequired' | translate }}
      </mat-error>
      <mat-error
        *ngIf="passCodeForm.controls['passCode'].touched && passCodeForm.controls['passCode'].errors?.['pattern']">
        {{ 'setPassCode.validation.passCodeLimit' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-md-4 mb-3">
    <mat-form-field>
      <mat-label>
        {{ 'setPassCode.confirmPassCode' | translate }}
      </mat-label>
      <input id="textbox_setPassCodeConfirmPassCode" matInput type="password" maxlength="4" autocomplete="off"
        formControlName="confirmPassCode" [placeholder]="('setPassCode.confirmPassCode' | translate)" />
      <mat-error *ngIf="submitted && passCodeForm.controls['confirmPassCode'].errors?.['required']">
        {{ 'setPassCode.validation.confirmPassCodeRequired' | translate }}
      </mat-error>
      <mat-error
        *ngIf="passCodeForm.controls['confirmPassCode'].touched && passCodeForm.controls['confirmPassCode'].errors?.['mismatch']">
        {{ 'setPassCode.validation.confirmPassCodeMatch' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-md-4 mb-3">
    <button id="btn_save" mat-flat-button color="primary">
      {{ 'buttons.save' | translate }}
    </button>
    <a id="btn_back" mat-button [routerLink]="['/']">
      {{ 'buttons.back' | translate }}
    </a>
  </div>
</form>