<div class="auth">
  <app-auth-header></app-auth-header>
  <div class="callback-loading" *ngIf="!isCodeValid">
    <img src="assets/loading.gif" alt="loading" width="100" height="100">
  </div>
  <div class="row justify-content-center" *ngIf="isCodeValid">
    <div class="col-md-4 col-xl-4">
      <mat-card class="auth__card" *ngIf="!passwordCofirmed">
        <mat-card-header class="justify-content-center margin-bottom-m">
          <mat-card-title>
            <h2>{{ "auth.changePassword" | translate }}</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" id="resetPasswordForm" class="login__form"
            *ngIf="isCodeValid">
            <mat-form-field class="login__text-field">
              <mat-label>{{ "resetPassword.password" | translate }}</mat-label>
              <input id="textbox_resetPasswordEnterPassword" matInput
                [placeholder]="('resetPassword.password' | translate)" [type]="passwordField ? 'password' : 'text'"
                formControlName="password" #passwordRef autocomplete="Off" />
              <icon [name]="passwordField ? 'visibility_off' : 'visibility'" matSuffix class="login__show-password"
                (click)="passwordField = !passwordField">
              </icon>
              <mat-error *ngIf="submitted && resetPasswordForm.controls['password'].errors?.['required']">
                {{ "resetPassword.validation.passwordRequired" | translate }}
              </mat-error>
              <mat-error
                *ngIf="resetPasswordForm.get('password')?.touched && resetPasswordForm.controls['password'].errors?.['minlength']">
                {{ "resetPassword.validation.passwordMinlength" | translate }}
              </mat-error>
              <mat-error
                *ngIf="resetPasswordForm.get('password')?.touched && resetPasswordForm.controls['password'].errors?.['hasNumber']">
                {{ "resetPassword.validation.passwordHasNumber" | translate }}
              </mat-error>
              <mat-error
                *ngIf="resetPasswordForm.get('password')?.touched && resetPasswordForm.controls['password'].errors?.['hasCapitalCase']">
                {{ "resetPassword.validation.passwordHasCapitalCase" | translate }}
              </mat-error>
              <mat-error
                *ngIf="resetPasswordForm.get('password')?.touched && resetPasswordForm.controls['password'].errors?.['hasSmallCase']">
                {{ "resetPassword.validation.passwordHasSmallCase" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="login__text-field">
              <mat-label>{{ "resetPassword.confirmPassword" | translate }}</mat-label>
              <input id="textbox_resetPasswordConfirmPassword" matInput
                [placeholder]="('resetPassword.confirmPassword' | translate)"
                [type]="passwordField ? 'password' : 'text'" formControlName="confirmPassword" autocomplete="Off">
              <icon [name]="passwordField ? 'visibility_off' : 'visibility'" matSuffix class="login__show-password"
                (click)="passwordField = !passwordField">
              </icon>
              <mat-error *ngIf="submitted && resetPasswordForm.get('confirmPassword')?.errors?.['required']">
                {{ "resetPassword.validation.confirmPasswordRequired" | translate }}
              </mat-error>
              <mat-error
                *ngIf="resetPasswordForm.get('confirmPassword')?.touched && resetPasswordForm.get('confirmPassword')?.errors?.['mismatch']">
                {{ "resetPassword.validation.passwordMissMatch" | translate }}
              </mat-error>
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <button id="btn_changePassword" mat-flat-button color="primary" type="submit" form="resetPasswordForm"
            class="button--block d-flex" [class.spinner]="loading" [disabled]="loading">
            {{ "buttons.changePassword" | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
      <mat-card class="auth__card" *ngIf="passwordCofirmed">
        <div class="d-flex flex-column align-items-center margin-top-l margin-bottom-base">
          <svg width="132" height="132" viewBox="0 0 132 132" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_54187_46682)">
              <circle cx="66" cy="66" r="65.5" fill="white" stroke="#72777F" />
              <path opacity="0.42"
                d="M109.933 94.0001C123.934 96.0001 122.776 101.442 109.933 113.5C98.3634 124.363 82.8985 131 65.9091 131C30.0608 131 1 101.451 1 65.0001C1.98347 42.5 22.6848 101.5 58.5331 101.5C94.3814 101.5 91.9711 92.5001 109.933 94.0001Z"
                fill="#DEE3EB" />
              <path
                d="M59.4869 93C57.9301 93.0029 56.4146 92.4959 55.1689 91.5554L55.0917 91.4971L38.8305 78.9582C38.0775 78.377 37.4454 77.6522 36.9704 76.8251C36.4955 75.9979 36.187 75.0847 36.0625 74.1374C35.938 73.1902 36 72.2276 36.2449 71.3045C36.4898 70.3813 36.9128 69.5158 37.4898 68.7574C38.0668 67.9989 38.7865 67.3624 39.6078 66.884C40.4291 66.4057 41.3359 66.095 42.2764 65.9696C43.2169 65.8442 44.1728 65.9066 45.0894 66.1533C46.0059 66.3999 46.8653 66.826 47.6184 67.4071L58.1512 75.541L83.0417 42.8487C83.619 42.0904 84.3389 41.4541 85.1604 40.9761C85.9818 40.498 86.8887 40.1876 87.8293 40.0626C88.7699 39.9375 89.7257 40.0003 90.6422 40.2473C91.5587 40.4943 92.4179 40.9207 93.1707 41.5021L93.016 41.7137L93.1748 41.5052C94.6934 42.6807 95.6871 44.4149 95.9378 46.3274C96.1884 48.2398 95.6756 50.1743 94.5118 51.7064L65.2345 90.1605C64.5574 91.0466 63.6867 91.7637 62.6899 92.2561C61.6931 92.7485 60.5971 93.0031 59.4869 93Z"
                fill="#00629D" />
            </g>
            <defs>
              <clipPath id="clip0_54187_46682">
                <rect width="132" height="132" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <mat-card-header class="text-center margin-bottom-m">
          <mat-card-title>
            {{ "auth.passwordChanged" | translate }}
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="text-center margin-bottom-base">
          <p>{{ "auth.passwordHasBeen" | translate }}</p>
        </mat-card-content>
        <mat-card-actions>
          <a [routerLink]="['/login']" mat-flat-button color="primary" id="btn_loginNow" class="button--block">
            {{ "buttons.logInNow" | translate }}
          </a>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="auth__footer">
    <app-auth-footer></app-auth-footer>
  </div>
</div>