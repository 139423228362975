import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@auth';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPassForm: FormGroup = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    captcha: ['', Validators.required]
  });

  emailSent: boolean = false;
  loading: boolean = false;
  submitted: boolean = false;

  @ViewChild('emailRef') emailRef?: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {
    // Redirect to home if already logged in
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    // Set focus to email
    this.emailRef?.nativeElement.focus();
  }

  onSubmit() {
    this.submitted = true;

    // Stop here if form is invalid
    if (this.forgotPassForm.invalid) {
      return;
    }

    this.loading = true;

    this.authService.requestResetPassword(this.forgotPassForm.controls['captcha'].value, this.forgotPassForm.controls['email'].value)
      .subscribe({
        next: (res: boolean) => {
          this.emailSent = true
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;
          this.forgotPassForm.controls['captcha'].reset();
          this.snackBar.open(err.error[0], '✖', { direction: this.translateService.instant('uiDirection') });
        }
      });
  }

}
