<div class="auth">
  <app-auth-header></app-auth-header>
  <div class="row justify-content-center">
    <div class="col-md-4 col-xl-4">
      <mat-card class="auth__card">
        <mat-card-header class="margin-bottom-m">
          <mat-card-title>
            <h2>{{ "login.login" | translate }}</h2>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" id="loginForm">
            <mat-form-field>
              <mat-label>{{ "login.email" | translate }}</mat-label>
              <input id="textbox_loginEmail" matInput [placeholder]="('login.email' | translate)"
                formControlName="email" #emailRef />
              <mat-error *ngIf="submitted && loginForm.controls['email'].errors?.['required']">
                {{ "login.validation.email" | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ "login.password" | translate }}</mat-label>
              <input id="textbox_loginPassword" matInput [placeholder]="('login.password' | translate)"
                [type]="passwordField ? 'password' : 'text'" formControlName="password" autocomplete="off" />
              <icon [name]="passwordField ? 'visibility_off' : 'visibility' " matSuffix class="login__show-password"
                (click)="passwordField = !passwordField">
              </icon>
              <mat-error *ngIf="submitted && loginForm.controls['password'].errors?.['required']">
                {{ "login.validation.password" | translate }}
              </mat-error>
            </mat-form-field>
            <div class="d-flex justify-content-between align-items-center">
              <mat-checkbox id="checkbox_loginRememberMe" formControlName="rememberMe">
                {{ "login.rememberMe" | translate }}
              </mat-checkbox>
              <a [routerLink]="['/forgot-password']" mat-button color="primary" id="btn_forgetPassword">
                {{ 'buttons.forgotPassword' | translate }}
              </a>
            </div>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <button id="btn_login" mat-flat-button color="primary" type="submit" form="loginForm"
            class="button--block d-flex">
            <mat-spinner id="spinner" diameter="18" class="spinner--white margin-end-s" *ngIf="loading"></mat-spinner>
            {{ "buttons.login" | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
  <div class="auth__footer">
    <app-auth-footer></app-auth-footer>
  </div>
</div>