import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-footer',
  templateUrl: './auth-footer.component.html',
  styleUrl: './auth-footer.component.scss'
})
export class AuthFooterComponent {

  version: string = '';
  copyrightYear: number = new Date().getFullYear();

  constructor() { }

  ngOnInit() {
    this.version = environment.version;
  }

}
